<template>
    <modal ref="modalAgregarEquipo" tamano="modal-md"
        :titulo="`${texto} equipo modelo`"
        :adicional="`${texto}`" @adicional="accion"
    >
        <div class="mx-2 my-3 f-15">
            <ValidationObserver ref="validator">
                <div class="d-middle-center my-2">
                    <div style="width:161px">
                        <clean-cropper
                            ref="cleanCropper"
                            v-model="image"
                            :image="editImagen"/>
                    </div>
                </div>

                <ValidationProvider name="nombre" rules="required" v-slot="{ errors }">
                    <p class="input-label-top mt-4">Nombre del equipo</p>
                    <el-input v-model="payload.nombre" class="f-12 br-5 w-100" size="small" />
                    <vee-error :text="errors[0]"/>
                </ValidationProvider>

                <ValidationProvider name="descripción" rules="required" v-slot="{ errors }">
                    <p class="input-label-top mt-4">Descripción</p>
                    <el-input v-model="payload.descripcion" type="textarea" :autosize="{ minRows: 3}" class="f-12 br-5 w-100" size="small" />
                    <vee-error :text="errors[0]"/>
                </ValidationProvider>

                <p class="input-label-top mt-4">SKU</p>
                <el-input v-model="payload.sku" class="f-12 br-5 w-50" maxlength="20" size="small" />

                <div class="row">
                    <div class="col-6">
                        <ValidationProvider name="categoria" rules="required" v-slot="{ errors }">
                            <p class="input-label-top mt-4">Categoria</p>
                            <el-select v-model="payload.id_categoria" size="small" class="w-100" @change="getSubCategorias">
                                <el-option
                                    v-for="(category, index) in select_categorias"
                                    :key="index"
                                    :label="category.categoria"
                                    :value="category.id">
                                </el-option>
                            </el-select>
                            <vee-error :text="errors[0]"/>
                        </ValidationProvider>
                    </div>
                    <div class="col-6">
                        <p class="input-label-top mt-4">Subcategoria</p>
                        <el-select v-model="payload.id_sub_categoria" size="small" class="w-100">
                            <el-option
                                v-for="(subCategory, index) in select_sub_categorias"
                                :key="index"
                                :label="subCategory.categoria"
                                :value="subCategory.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>

                <el-switch
                    v-model="payload.estado"
                    active-text="Activo"
                    inactive-text="Inactivo"
                    :active-value="9"
                    :inactive-value="10"
                    class="mt-4">
                </el-switch>
            </ValidationObserver>
        </div>
    </modal>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
    data(){
        return {
            image: null,
            editImagen: '',

            payload: {
                estado: 10,
                nombre: '',
                sku: '',
                adjunto_principal: '',
                descripcion: '',
                id_categoria: '',
                id_sub_categoria: ''
            },

            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen del equipo',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
        }
    },
    computed: {
        ...mapGetters({
            select_categorias: 'selects/selects/select_categorias',
            select_sub_categorias: 'selects/selects/select_sub_categorias',
            key: 'files/files/key'
        }),
        editar() { // Funcion editar: true // Funcion crear: false
            return this.$route.name === 'almacen.equipos.ver' ? true : false
        },
        texto(){
            return this.editar ? 'Editar' : 'Crear'
        }
    },
    methods: {
        ...mapActions({
            Action_create_equipos_modelos: 'almacen/equiposModelos/Action_create_equipos_modelos',
            Action_edit_equipo_modelo: 'almacen/equiposModelos/Action_edit_equipo_modelo',
            Action_get_equipos_modelos: 'almacen/equiposModelos/Action_get_equipos_modelos',
            Action_get_detalle_equipos_modelos: 'almacen/equiposModelos/Action_get_detalle_equipos_modelos',
            Action_get_selects_categorias: 'selects/selects/Action_get_selects_categorias',
            uploadFile: 'files/files/Action_save_file_to_aws',
            Action_get_detalle_cabecera: 'almacen/equiposModelos/Action_get_detalle_cabecera',
        }),
        toggle(val){
            this.cleanModal()
            if ( this.editar ) this.setVal(val)
            this.$refs.modalAgregarEquipo.toggle()
        },
        setVal(val){
            this.payload.nombre = val?.nombre ?? ''
            this.payload.descripcion = val?.descripcion ?? ''
            this.payload.sku = val?.sku ?? ''
            this.payload.adjunto_principal = val?.imagen ?? ''
            this.editImagen = val?.imagen ?? ''
            this.payload.id_categoria = val?.id_categoria ?? ''
            this.payload.estado = val?.estado ?? 10
            this.getSubCategorias(val.id_categoria)
            this.payload.id_sub_categoria = val?.id_sub_categoria ?? ''
        },
        async accion(){
            const valid = await this.$refs.validator.validate();
            if(!valid) return;

            this.editar ? await this.editarEquipoModelo() : this.crearEquipoModelo()
        },
        async crearEquipoModelo(){
            await this.uploadImage()

            await this.Action_create_equipos_modelos(this.payload)
            await this.Action_get_equipos_modelos()
            this.$refs.modalAgregarEquipo.toggle()
        },
        async uploadImage(){
			if(this.image){
				const base64Image = this.image
				await this.uploadFile({
					file: await this.base64ToFile(base64Image),
					path: '/almacen/equipos_modelos'
				});
				this.payload.adjunto_principal = this.key
			}
        },
        async editarEquipoModelo(){
            this.image ? await this.uploadImage() : () => {}

            await this.Action_edit_equipo_modelo({id: this.$route.params.id, payload: this.payload})
            await this.Action_get_detalle_cabecera(this.$route.params.id)
            this.$refs.modalAgregarEquipo.toggle()
        },
        async getSubCategorias(id_categoria){
            this.payload.id_sub_categoria = ''
            await this.Action_get_selects_categorias({id_categoria})
        },
        cleanModal() {
            this.$refs.validator.reset();
            this.$refs.cleanCropper.reset();
            this.payload.nombre = ''
            this.payload.descripcion = ''
            this.payload.sku = ''
            this.payload.adjunto_principal = ''
            this.editImagen = ''
            this.payload.id_categoria = ''
            this.payload.estado = 10
            this.payload.id_sub_categoria = ''
        }
    }
}
</script>

<style lang="css" scoped>
</style>
